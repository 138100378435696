import React, { FC, useContext, useEffect, useState } from 'react';
import { Button, ProgressSpinner, IconButton } from '@jsluna/react';
import {
  ProfileCardWrapper,
  ProfileEmailEdit,
  RelativeDiv,
} from './profileCard.styles';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import updateProfileSchema from 'src/components/Forms/schemas/updateProfile.schema';
import { NotificationType } from 'src/enums/notificationTypes.enum';
import { gql } from '@apollo/client';
import { ChangeEmailLink } from './components/changeEmail';
import { ProfileDetailRow } from '../profileDetailRow';
import { Drawer } from '@mui/material';
import { UpdateProfileScreen } from './screens/updateProfileScreen';
import { UpdateProfileEmailScreen } from './screens/updateProfileEmailScreen';
import { APP_ACCESS } from 'src/enums/permissions.enum';
import { AuthContext } from 'src/providers/AuthProvider';

import { Cancel } from '@jsluna/icons';
import { RemoveProfileAccessPopup } from './components/removeProfileAccessPopup';
import { ProfilePopupFeedback } from './components/profilePopupFeedback';

import {
  ProfileCard_ProfileInfoFragment,
  useRemoveProfileAccessMutation,
  useInviteProfileMutation,
  useUpdateProfileEmailAddressMutation,
  useUpdateProfileMutation,
  useResyncProfileEmailMutation,
} from 'src/operations/generated/graphql';
import { ResyncProfileEmail } from './components/resyncProfile';
import { ResyncProfileEmailPopup } from './components/resyncProfileEmailPopup';

const CancelIcon = Cancel as unknown as React.FC;

interface IProfileCard {
  profile: ProfileCard_ProfileInfoFragment;
  setProfile: (status: ProfileCard_ProfileInfoFragment) => void;
  isViewProfile?: boolean;
}

interface IDetailsToChange {
  title?: string;
  firstName?: string;
  lastName?: string;
  emailAddress?: string;
  countryCode?: string;
  contactNumber?: string;
}
enum PanelAction {
  EDIT_DETAILS = 'EDIT_DETAILS',
  EDIT_EMAIL = 'EDIT_EMAIL',
}

gql`
  fragment ProfileCard_ProfileInfo on ProfileProjection {
    id
    firstName
    lastName
    preferredName
    emailAddress
    contactNumber
    title
    countryCode
    providerId
    status
  }
`;

export const ProfileCard: FC<IProfileCard> = ({
  profile,
  setProfile,
  isViewProfile,
}) => {
  // @ts-ignore
  const { hasPermission } = useContext(AuthContext);

  const {
    firstName,
    lastName,
    preferredName,
    emailAddress,
    contactNumber,
    title,
    countryCode,
    id,
    providerId,
    status,
  } = profile;
  const [editEmail, setEditEmail] = useState<boolean>(false);
  const [showSidePanel, setShowSidePanel] = useState<PanelAction | null>(null);
  const [showPopup, setShowPopup] = useState(false);
  const [hasProviderId, setHasProviderId] = useState<boolean>(false);
  const [showResyncPopup, setShowResyncPopup] = useState(false);
  const [feedBackMessage, setFeedBackMessage] = useState<{
    notificationFor: 'INVITE' | 'REVOKE' | 'RESYNC' | 'ACTIVATE';
    type: NotificationType;
  } | null>(null);

  const formObj = useForm({
    resolver: yupResolver(updateProfileSchema),
    mode: 'onChange',
  });

  const {
    handleSubmit,
    formState: { errors },
    setValue,
  } = formObj;

  const [mutate, { loading, error }] = useUpdateProfileMutation();

  const [emailMutate, { loading: emailLoading, error: emailError }] =
    useUpdateProfileEmailAddressMutation();

  useEffect(() => {
    if (title) {
      setValue('title', title);
    }
    if (countryCode) {
      setValue('countryCode', countryCode);
    }
    if (providerId) {
      setHasProviderId(true);
    }
  }, []);

  const handleConfirmation = async (value: IDetailsToChange) => {
    try {
      const valuesToSubmit = Object.fromEntries(
        Object.entries(value).filter(
          ([_, entry]) => entry !== ('' || undefined),
        ),
      );
      await mutate({
        variables: {
          id: profile.id,
          profile: valuesToSubmit,
        },
      });
      setProfile({ ...profile, ...valuesToSubmit });
    } catch (e) {}
  };

  const handleEmailConfirmation = async (value: any) => {
    try {
      await emailMutate({
        variables: {
          id,
          input: {
            emailAddress: value.emailAddress,
          },
        },
      });
      setProfile({ ...profile, ...value });
    } catch (e) {}
  };
  const [
    removeProfileAccessMutate,
    { error: removeProfileAccessError, loading: removeProfileAccessLoading },
  ] = useRemoveProfileAccessMutation();
  const [
    resyncProfileEmailMutate,
    { error: resyncProfileEmailError, loading: resyncProfileEmailLoading },
  ] = useResyncProfileEmailMutation();

  const handleRemoveProfileAccess = async (id: string) => {
    try {
      await removeProfileAccessMutate({
        variables: {
          id,
        },
      });
      setHasProviderId(false);
    } catch (e) {}
  };
  const handleResyncProfileEmail = async (id: string) => {
    try {
      await resyncProfileEmailMutate({
        variables: {
          id,
        },
      });
    } catch (e) {}
  };

  const [inviteProfileMutate] = useInviteProfileMutation();
  const handleInviteProfile = async (id: string) => {
    try {
      await inviteProfileMutate({
        variables: {
          id,
          options: {
            force: true,
          },
        },
      });
      setFeedBackMessage({
        notificationFor: 'INVITE',
        type: NotificationType.SUCCESS,
      });
      setHasProviderId(true);
    } catch (e) {
      setFeedBackMessage({
        notificationFor: 'INVITE',
        type: NotificationType.FAILURE,
      });
    }
  };

  const handleActivateADUser = async (providerId: string) => {
    try {
      await fetch(`/profile-service/user/activate/${providerId}`, { method: 'POST', mode: 'cors' })
        .then(res => {
          if (res.status === 201) {
            setFeedBackMessage({
              notificationFor: 'ACTIVATE',
              type: NotificationType.SUCCESS,
            });
          } else {
            throw new Error(`${res.status}`);
          }
        })
        .catch(err =>
          setFeedBackMessage({
            notificationFor: 'ACTIVATE',
            type: NotificationType.FAILURE,
          }),
        ); 
    } catch (e) {
      setFeedBackMessage({
        notificationFor: 'ACTIVATE',
        type: NotificationType.FAILURE,
      });
    }
  };

  const handlePanelClose = () => {
    setShowSidePanel(null);
  };

  const renderPanel = () => {
    switch (showSidePanel) {
      case PanelAction.EDIT_DETAILS:
        return (
          <UpdateProfileScreen
            target={{ id: profile.id, displayName: 'profile' }}
            loading={loading}
            error={error}
            handleClose={handlePanelClose}
            handleConfirmation={handleConfirmation}
            heading='Edit profile'
            profile={profile}
          />
        );
      case PanelAction.EDIT_EMAIL:
        return (
          <UpdateProfileEmailScreen
            target={{ id: profile.id, displayName: 'profile' }}
            loading={emailLoading}
            error={emailError}
            handleClose={handlePanelClose}
            handleConfirmation={handleEmailConfirmation}
            heading='Edit email'
            profile={profile}
          />
        );
    }
  };
  return (
    <>
      <RelativeDiv>
        {feedBackMessage && (
          <>
            <ProfilePopupFeedback
              notificationFor={feedBackMessage.notificationFor}
              type={feedBackMessage.type}
              onClose={() => {
                setFeedBackMessage(null);
              }}
            />
          </>
        )}
      </RelativeDiv>
      <ProfileCardWrapper>
        <div className='name-section'>
          <div className='info'>
            <div className='profile-name'>
              {firstName} {lastName}
            </div>
            <div className='email'>{emailAddress}</div>
          </div>
          <div className='action'>
            {isViewProfile && hasProviderId &&
              hasPermission(APP_ACCESS.PROFILE_INVITATION_MANAGEMENT) && (
                <Button
                  variant='filled'
                  onClick={() => {
                    providerId && handleActivateADUser(providerId);
                  }}
                >
                  Activate AD
                </Button>
              )}
            {isViewProfile && status === 'APPROVED' && !hasProviderId
              ? hasPermission(APP_ACCESS.PROFILE_INVITATION_MANAGEMENT) && (
                  <IconButton
                    variant='filled'
                    label='Invite'
                    onClick={() => {
                      handleInviteProfile(id);
                    }}
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/img/invite.svg`}
                      alt='Invite'
                    />
                  </IconButton>
                )
              : isViewProfile && status === 'APPROVED' &&
                hasProviderId &&
                hasPermission(APP_ACCESS.REMOVE_PROFILE) && (
                  <IconButton
                    variant='filled'
                    label='Remove access'
                    onClick={() => {
                      setShowPopup(true);
                    }}
                  >
                    <CancelIcon />
                  </IconButton>
                )}
          </div>
        </div>
        <div className='profile-details'>
          <ProfileDetailRow label='Id' value={id} name='id' />
          <ProfileDetailRow
            label='Title'
            value={title || 'UNAVAILABLE'}
            name='title'
          />
          <ProfileDetailRow
            label='First name'
            value={firstName || 'UNAVAILABLE'}
            name='firstName'
          />
          <ProfileDetailRow
            label='Last name'
            value={lastName || 'UNAVAILABLE'}
            name='lastName'
          />
          {preferredName && (
            <ProfileDetailRow
              label='Preferred name'
              value={preferredName}
              name='PreferredName'
            />
          )}
          <ProfileEmailEdit editEmail={isViewProfile && editEmail}>
            <ProfileDetailRow
              label={'Email'}
              value={profile.emailAddress || 'UNAVAILABLE'}
              name={'emailAddress'}
              cssClass='email-row'
            >
              {isViewProfile &&
                hasPermission(APP_ACCESS.UPDATE_USER_DETAILS) && (
                  <div className='profile-email-actions'>
                    <ChangeEmailLink
                      onClick={() => {
                        setShowSidePanel(PanelAction.EDIT_EMAIL);
                      }}
                      showCancel={editEmail}
                    />
                    <ResyncProfileEmail
                      onClick={() => {
                        setShowResyncPopup(true);
                      }}
                      showCancel={editEmail}
                    />
                  </div>
                )}
            </ProfileDetailRow>
          </ProfileEmailEdit>
          <ProfileDetailRow
            label='Country code'
            value={countryCode || 'UNAVAILABLE'}
            name='countryCode'
          />
          <ProfileDetailRow
            label='Contact number'
            value={contactNumber || 'UNAVAILABLE'}
            name='contactNumber'
          />
          {isViewProfile && (
            <ProfileDetailRow
              label='Status'
              value={status || 'UNAVAILABLE'}
              name='status'
            />
          )}
          {hasPermission(APP_ACCESS.GLOBAL_ACCOUNT_TYPE_ADMIN) && (
            <ProfileDetailRow
              label='Provider ID'
              value={providerId || 'UNAVAILABLE'}
              name='providerId'
            />
          )}
          <div className='action-button'>
            {(hasPermission(APP_ACCESS.UPDATE_USER_DETAILS) ||
              !isViewProfile) && (
              <Button
                variant='text'
                circle={false}
                disabled={false}
                hard={false}
                element='button'
                color='dark'
                onClick={() => setShowSidePanel(PanelAction.EDIT_DETAILS)}
              >
                Edit details
              </Button>
            )}
          </div>
          <Drawer
            open={showSidePanel !== null}
            onClose={() => {}}
            anchor='right'
            className='drawer-on-top edit-account-type'
          >
            {renderPanel()}
          </Drawer>
        </div>
        <RemoveProfileAccessPopup
          profile={profile}
          showPopup={showPopup}
          setShowPopup={setShowPopup}
          setFeedBackMessage={setFeedBackMessage}
          handleConfirmation={handleRemoveProfileAccess}
          loading={removeProfileAccessLoading}
          error={removeProfileAccessError}
        />
        <ResyncProfileEmailPopup
          profile={profile}
          showResyncPopup={showResyncPopup}
          setShowResyncPopup={setShowResyncPopup}
          setFeedBackMessage={setFeedBackMessage}
          handleConfirmation={handleResyncProfileEmail}
          loading={resyncProfileEmailLoading}
          error={resyncProfileEmailError}
        />
      </ProfileCardWrapper>
    </>
  );
};
